import React from 'react';

//	S T Y L E S

import './md-vehicle-importer.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___toast___controller } from '../../types/types.toasts';

//	S T A T E S

import { props___MdVehicleImportModal, state___MdVehicleImportModal, state___MdVehicleImportModal___default } from './md-vehicle-importer.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class MdVehicleImportModal extends React.Component<props___MdVehicleImportModal, state___MdVehicleImportModal> {

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdVehicleImportModal,
	) {
		super(props);
		this.state = state___MdVehicleImportModal___default
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___importable_vehicles = async () => {
		const ___vehicles___imported___temp: type___api___response = await this._API.sync_vehicles___from___subito_it___pre();
		const ___vehicles___imported___temp___list: any[] = ___vehicles___imported___temp.data;
		this.setState({
			vehicle_to_import___list: ___vehicles___imported___temp___list,
		//	vehicle_to_import___list___definitive: ___vehicles___imported___temp___list.map((___c: any) => { return ___c['vehicle_slug']; }),
			vehicle_to_import___list___isLoading: false,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___vehicles_import___begin___toggle_class = (___slug: string) => {
		this.setState((prevState: state___MdVehicleImportModal) => {
			const { vehicle_to_import___list___definitive } = prevState;
			return {
				vehicle_to_import___list___definitive: vehicle_to_import___list___definitive.includes(___slug)
				? vehicle_to_import___list___definitive.filter((c) => c !== ___slug)
				: [...vehicle_to_import___list___definitive, ___slug],
			};
		});
	};

//#endregion

//#region 																							S U B M I T 

	private submit___vehicle_import = () => {
		(async () => {
			const ___vehicles___imported: type___api___response = await this._API.sync_vehicles___from___subito_it(this.state.vehicle_to_import___list___definitive);
			if (___vehicles___imported['response'] === 'success') {
				const ___toast_props: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Hai importato correttamente i veicoli presenti sui tuoi servizi connessi</>
				};
				this.props.toast___setContent(___toast_props);
				this.props.toast___setShowing(true);
			} else {
				const ___toast_props: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Non è stato possibile importare tutti i veicoli, riprova più tardi...</>
				}
				this.props.toast___setContent(___toast_props);
				this.props.toast___setShowing(true);
			}
		})();
		this.props.event___onDismiss(true);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		await this.read_props___importable_vehicles();
	}

//#endregion

//#region 																							R E N D E R

	render() {
		return <>
			<div className="md-vehicle-importer---opacizer" onClick={() => { this.props.event___onDismiss(null); }}></div>
			<div className="md-vehicle-importer---container is-card">
				{(this.state.GUI___modal___is_uploading === false) ? <>

					<br />

					<h3>Vuoi importare i seguenti veicoli?</h3>

					<br />
					<br />

					<p>La procedura può durare da qualche secondo a qualche minuto e verrà eseguita in background</p>

					<br />
					<br />

					<p><b>Seleziona i veicoli da importare</b></p>

					<br />
					<br />

					<section className="md-vehicle-importer---cars-container">
						{(this.state.vehicle_to_import___list___isLoading === true) ? <>
							<ComSpinnerComponent />
						</> : <>
							{this.state.vehicle_to_import___list.map((___car_to_check: any) => { return <>
								<label key={___car_to_check['vehicle_slug']}>
									<input type="checkbox" checked={this.state.vehicle_to_import___list___definitive.includes(___car_to_check['vehicle_slug'])} onChange={() => this.handle___vehicles_import___begin___toggle_class(___car_to_check['vehicle_slug'])} />
									<span>{___car_to_check['vehicle_name']}</span>
								</label>
							</>;})}
						</>}
					</section>

					<br />
					<br />

					<div className="page-container---bottom-bar---buttons is-centered">
						<button type="button" className="is-card no-shadow" onClick={() => { this.props.event___onDismiss(null); }}>
							<i className="fas fa-times"></i>
							<span>Chiudi</span>
						</button>
						<button type="button" className="is-card is-submit no-shadow" onClick={ this.submit___vehicle_import }>
							<i className="fas fa-file-import"></i>
							<span className="is-full-width">Conferma Importazione</span>
						</button>
					</div>

				</> : <>
					<ComSpinnerComponent/>
				</>}
			</div>
		</>;
	}

//#endregion

}