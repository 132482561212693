import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasLicence } from "../../../interfaces/interface.licence";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { type___request___order } from "../../../types/types.types";

//	P R O P S

export interface props___SbVehiclesListPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence {

}

//	S T A T E

export interface state___SbVehiclesListPage {

	search_param___vehicles: string,

	to_import___modal___isOpen: boolean,

	vehicles_list___is_importing: boolean | null,
	vehicles_list: any[],
	vehicles_list___is_loading: boolean,
	vehicles_list___order: type___request___order,

	GUI___page___is_importing: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbVehiclesListPage___default: state___SbVehiclesListPage = {

	search_param___vehicles: '',

	to_import___modal___isOpen: false,

	vehicles_list___is_importing: null,
	vehicles_list: [],
	vehicles_list___is_loading: true,
	vehicles_list___order: { param: 'createdAt', direction: -1 },

	GUI___page___is_importing: false,

}