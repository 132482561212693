import { props___page___hasToast } from "../../interfaces/interface.toasts"
import { type___modals___controller } from "../../types/types.modals"

//	P R O P S

export interface props___MdVehicleImportModal extends type___modals___controller, props___page___hasToast {
	
}

//	S T A T E

export interface state___MdVehicleImportModal {

	vehicle_to_import___list: any[],
	vehicle_to_import___list___isLoading: boolean,
	vehicle_to_import___list___definitive: string[],

	GUI___modal___is_uploading: boolean,

}

//	S T A T E   D E F A U L T 

export const state___MdVehicleImportModal___default: state___MdVehicleImportModal = {

	vehicle_to_import___list: [],
	vehicle_to_import___list___isLoading: true,
	vehicle_to_import___list___definitive: [],

	GUI___modal___is_uploading: false,

}